import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { MdStar, MdOutlineWorkOutline, MdOutlineLocationOn, MdPermPhoneMsg } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { currencies, GOOGLE_MAP_API_KEY, SocialMapScreenStyles, TaskExamples } from '../../constants/config';
import { useDispatch, useSelector } from 'react-redux';
import LocationMarker from '../../components/Markers/LocationMarker';
import JobService from '../../services/JobService';
import { NotificationManager } from "react-notifications";
import { getActiveJob, getHeroActiveBid } from '../../redux/actions/app';
import CancelJobModal from '../../components/CancelJobModal';
import { isEmpty } from '../../utils/common';
import ClothModal from '../../components/ClothModal';
import ClothItem from '../../components/ClothItem';
import Avatar from '../../components/Avatar';
import { Spin } from 'antd';
import HeroMap from '../../components/Maps/HeroMap';


const HeroJobHome = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { job_id } = useParams();
    const { state } = useLocation();
    const { activeJob } = useSelector(state => state.app);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showConfirmComplete, setShowConfirmComplete] = useState(false);
    const [loading, setLoading] = useState(false); 

    const onImHere = async () => {
        setLoading(true);
        try {
            await JobService.updateHeroArrived({
                job_id: activeJob?.id
            });
            await dispatch(getActiveJob());
            setLoading(false);
        } catch (error) {
            setLoading(false);
            NotificationManager.error(
                error?.message ||
                "Oops! Something went wrong. Please try again or contact us directly at contact@heromay.com.",
                "Error",
                3000
            );
        }
    }

    const onJobCompeted = async () => {
        try {
            setLoading(true);
            await JobService.complete({ job_id: job_id });
            await dispatch(getActiveJob());
            setLoading(false);
            navigate('/hero/jobs');
        } catch (error) {
            setLoading(false);
            NotificationManager.error(
                error?.message ||
                "Oops! Something went wrong. Please try again or contact us directly at contact@heromay.com.",
                "Error",
                3000
            );
        }
    }

    const handleCancelJob = (reason) => {
        setLoading(true);
        JobService.cancelJob({
            job_id: job_id,
            reason: reason
        }).then((res) => {
            console.log('cancel job ', res)
            setLoading(false);
            navigate('/customer');
        }).catch(e => {
            console.log(e)
            setLoading(false);
            NotificationManager.error(
                e?.message ||
                "Oops! Something went wrong. Please try again or contact us directly at contact@heromay.com.",
                "Error",
                3000
            );
        });
    }

    return (
        <div className='w-full h-full  flex flex-col justify-center items-center'>
            <div className='flex justify-start items-center h-[50px] w-full bg-primary1 px-4'>
                <div className='text-[16px] text-white font-semibold cursor-pointer'
                    onClick={() => setShowCancelModal(true)}
                >
                    Cancel
                </div>
            </div>
            <HeroMap  />
            <div className='w-full rounded-tl-[14px] rounded-tr-[14px] bg-[#323943] drop-shadow'>
                {
                    showConfirmComplete &&
                    <div className='w-full p-2 flex justify-center items-center rounded-tl-[14px] rounded-tr-[14px] bg-primary1'>
                        <p className='text-[15px] text-white font-bold'>Is the job completed?</p>
                    </div>
                }
                <div className=' p-4 w-full rounded-tl-[14px] rounded-tr-[14px]'>
                    <div className="w-full flex justify-start items-center"  >
                        <div className="mr-4 flex flex-col justify-center items-center">
                            <Avatar image={activeJob?.customer?.photo} />
                            <p className="mt-1 text-[12px] text-white font-bold">{activeJob?.customer?.name}</p>
                            <div className="flex justify-center items-center">
                                <MdStar color="#ffff00" size={13} />
                                <span className="text-[11px] text-white font-semibold">{parseFloat(activeJob?.customer?.rating).toFixed(1)} ({activeJob?.customer?.rating_cnt})</span>
                            </div>
                        </div>
                        <div className='flex-1 flex justify-start items-start'>
                            {
                                (!isEmpty(activeJob?.customer_wear_top) && !isEmpty(activeJob?.customer_wear_bottom)) &&
                                <ClothItem
                                    topName={activeJob?.customer_wear_top}
                                    bottomName={activeJob?.customer_wear_bottom}
                                    type={'both'}
                                />
                            }
                        </div>
                        <div className='w-[40px] h-[40px] rounded-full bg-primary2 flex justify-center items-center'>
                            <MdPermPhoneMsg color={'#1c1f24'} />
                        </div>
                    </div>
                    <div className="w-full mt-2 flex justify-start items-center">
                        <MdOutlineWorkOutline color="#fff" size={16} />
                        <p className="ml-2 w-full text-[16px] leading-[24px] text-white font-bold">{activeJob?.title}</p>
                    </div>
                    <div className="w-full mt-2 flex justify-start items-center">
                        <MdOutlineLocationOn color="#fff" size={16} />
                        <p className="ml-2 w-full text-[16px] leading-[21px] text-white ">{activeJob?.address_line1} {activeJob?.city} {activeJob?.country}</p>
                    </div>
                    <p className="mt-2 w-full text-[16px] leading-[21px] text-[#999] "> RM {parseInt(activeJob?.price)}</p>
                </div>
                <div className='w-full p-3 bg-primary1'>
                    {
                        !activeJob?.hero_arrived ?
                            <button className='w-full h-[40px] rounded-[10px] bg-primary2 text-white text-[15px]'
                                onClick={onImHere}
                            >
                                I'm here
                            </button>
                            :
                            (
                                !showConfirmComplete ?
                                    <button className='w-full h-[40px] rounded-[10px] bg-primary2 text-white text-[15px]'
                                        onClick={() => setShowConfirmComplete(true)}
                                    >
                                        Job Completed
                                    </button>
                                    :
                                    <div className='w-full'>
                                        {
                                            loading ?
                                                <div className=' w-full flex justify-center items-center h-[40px]'>
                                                    <Spin />
                                                </div>
                                                :
                                                <button className='w-full h-[40px] rounded-[10px] bg-primary2 text-white text-[15px]'
                                                    onClick={onJobCompeted}
                                                >
                                                    Yes
                                                </button>
                                        }
                                        <button className='mt-3 w-full h-[40px] rounded-[10px] bg-[#323943] text-white text-[15px]'
                                            onClick={() => setShowConfirmComplete(false)}
                                        >
                                            No
                                        </button>
                                    </div>
                            )

                    }
                </div>
            </div>
            {
                showCancelModal &&
                <CancelJobModal
                    showModal={showCancelModal}
                    onClose={(data) => {
                        setShowCancelModal(false);
                        if (!isEmpty(data)) {
                            handleCancelJob(data);
                        }
                    }}
                />
            }
            {
                (activeJob != null && (isEmpty(activeJob?.hero_wear_top) || isEmpty(activeJob?.hero_wear_bottom))) &&
                <ClothModal
                    showModal={(activeJob != null && (isEmpty(activeJob?.hero_wear_top) || isEmpty(activeJob?.hero_wear_bottom)))}
                    isCustomer={false}
                    job={activeJob}
                />
            }
        </div>
    )
}

export default HeroJobHome;