import React from 'react';
import Svg_loc from '../../assets/svgs/markers/pin.svg';

const LocationMarker = () => {
  return (
    <div className={'map-marker location-marker'}>
      <img src={Svg_loc} />
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return !(prevProps.lat !== nextProps.lat || prevProps.lng !== nextProps.lng);
}

export default LocationMarker;
