import { DEFAULT_LANG, GOOGLE_MAP_API_KEY } from '../constants/config';
import { setDefaults, fromLatLng as geocodeFromLatLng } from 'react-geocode';
import { extractCityInfo } from './common';
import { getLanguage } from './storage';

export const NO_PERMISSION = 'NO_PERMISSION';

export const checkLocationPermission = () => {
    return new Promise((resolve, reject) => {
        if ('geolocation' in navigator) {
            resolve('granted');
        } else {
            reject('Not available');
        }
        // if (navigator.permissions) {
        //   navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
        //     if (result.state === 'granted') {
        //       resolve(result.state);
        //     } else if (result.state === 'prompt') {
        //       resolve(result.state);
        //     } else if (result.state === 'denied') {
        //       reject('denied');
        //     }
        //   });
        // } else {
        //   reject('Not available');
        // }
    });
};

export const getCurrentLocation = () => {
    return new Promise((resolve, reject) => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    resolve({ latitude: position.coords.latitude, longitude: position.coords.longitude });
                },
                (error) => {
                    reject(error);
                },
                {
                    timeout: 15000
                }
            );
        } else {
            reject('Not available');
        }
    });
};

export const getAddressByCoordinates = ({ latitude, longitude }) => {
    return new Promise((resolve, reject) => {

        setDefaults({
            key: GOOGLE_MAP_API_KEY, // Your API key here.
            language: getLanguage() || DEFAULT_LANG, // Default language for responses. 
        });

        geocodeFromLatLng(latitude, longitude).then(
            (response) => {
                let data = extractCityInfo(response.results[0].address_components);

                resolve({
                    latitude: latitude,
                    longitude: longitude,
                    address_line1: data.address_line1,
                    address_line2: data.address_line2,
                    country: data.country,
                    city: data.city
                });
            },
            (error) => {
                console.error('Geocode.fromLatLng', error);
                reject(error);
            }
        );
    });
};
