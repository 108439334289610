import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import AuthMiddleware from "../authMiddleware.js";
import AuthedLayout from "../layouts/AuthedLayout.js";
import GuestLayout from "../layouts/GuestLayout.js";
import Login from "../../views/auth/Login.js";
import VerifyCode from "../../views/auth/VerifyCode.js";
import InitLocation from "../../views/onboard/InitLocation.js";
import Mode from "../../views/onboard/Mode.js";
import UserName from "../../views/onboard/UserName.js";
import CustomerHome from "../../views/customer/home.js";
import HeroLayout from "../layouts/HeroLayout.js";
import Jobs from "../../views/hero/jobs.js";
import JobDetails from "../../views/hero/job-details.js";
import JobBidList from "../../views/customer/job-bids.js";
import JobHome from "../../views/hero/job-home.js";
import HeroJobHome from "../../views/hero/job-home.js";
import CustomerJobHome from "../../views/customer/job-home.js";
import WaitHeros from "../../views/customer/wait-hero.js";
import Wallet from "../../views/hero/wallet.js";
import Safety from "../../views/settings/Safety.js";
import Faqs from "../../views/settings/Faqs.js";
import Settings from "../../views/settings/Setting.js";
import Profile from "../../views/settings/Profile.js";
import Rates from "../../views/settings/Rates.js";
import AboutUs from "../../views/settings/AboutUs.js";
import Contacts from "../../views/settings/Contacts.js";
import Terms from "../../views/settings/Terms.js";
import Privacy from "../../views/settings/Privacy.js";
import JobSettings from "../../views/settings/JobSettings.js";

const AppRoutes = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/"
          element={<GuestLayout />}
        >
          <Route exact path={'login'} element={<Login />} />
          <Route exact path={'verify-code'} element={<VerifyCode />} />
          <Route exact path={"about-us"} element={<AboutUs />} />
          <Route exact path={"contacts"} element={<Contacts />} />
          <Route exact path={"terms"} element={<Terms />} />
          <Route exact path={"privacy"} element={<Privacy />} />
        </Route>
        <Route path="/app" element={<AuthMiddleware><AuthedLayout /></AuthMiddleware>}>
          <Route exact path={"onboard/location"} element={<InitLocation />} />
          <Route exact path={"onboard/mode"} element={<Mode />} />
          <Route exact path={"onboard/username"} element={<UserName />} />
          <Route exact path={"safety"} element={<Safety />} />
          <Route exact path={"faqs"} element={<Faqs />} />
          <Route exact path={"settings"} element={<Settings />} />
          <Route exact path={"profile"} element={<Profile />} />
          <Route exact path={"rates"} element={<Rates />} />
        </Route>
        <Route path="/customer" element={<AuthMiddleware><AuthedLayout /></AuthMiddleware>}>
          <Route exact path={""} element={<CustomerHome />} />
          <Route exact path={"jobs/:job_id"} element={<JobBidList />} />
          <Route exact path={"accepted-job/:job_id"} element={<CustomerJobHome />} />
          <Route exact path={"wait-hero"} element={<WaitHeros />} />
        </Route>
        <Route path="/hero" element={<AuthMiddleware><HeroLayout /></AuthMiddleware>}>
          <Route exact path={""} element={<Jobs />} />
          <Route exact path={"jobs"} element={<Jobs />} />
          <Route exact path={"income"} element={<Jobs />} />
          <Route exact path={"wallet"} element={<Wallet />} />
          <Route exact path={"suport"} element={<Jobs />} />
        </Route>
        <Route path="/hero" element={<AuthMiddleware><AuthedLayout /></AuthMiddleware>}>
          <Route exact path={"jobs/:job_id"} element={<JobDetails />} />
          <Route exact path={"accepted-job/:job_id"} element={<HeroJobHome />} />
          <Route exact path={"job-settings"} element={<JobSettings />} />
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default AppRoutes;
