import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import IntlTelInput from 'intl-tel-input/react';
import 'intl-tel-input/build/css/intlTelInput.css';
import { isEmpty } from '../../utils/common';
import ImgWhatsapp from '../../assets/images/whatsapp.png';
import './index.css';
import { Spin } from 'antd';
import AuthService from '../../services/AuthService';
import { NotificationManager } from "react-notifications";
import { FaUsers, FaPhoneAlt } from "react-icons/fa";

const Login = () => {
    const navigate = useNavigate();
    const _phoneInput = useRef(null);
    const [phone, setPhone] = useState('');
    const [loading, setLoading] = useState(false);
    const onSubmit = () => {
        if (isEmpty(phone)) {
            return;
        }

        let fullPhone = phone;
        if (!phone.includes('+') && _phoneInput.current) {
            const data = _phoneInput.current.getInstance().getSelectedCountryData();
            fullPhone = '+' + data?.dialCode + phone;
        }
        
        console.log('phone ', fullPhone)

        setLoading(true);
        AuthService.sendVerifyCode({ phone: fullPhone })
            .then((res) => {
                setLoading(false);
                navigate('/verify-code', { state: { phone: fullPhone } })
            })
            .catch(e => {
                console.log(e)
                setLoading(false);
                NotificationManager.error(
                    e?.message ||
                    "Oops! Something went wrong. Please try again or contact us directly at contact@heromay.com.",
                    "Error",
                    3000
                );
            });
    }

    return (
        <div className='w-full h-full p-5 bg-white-primary1 flex flex-col justify-center items-center'>
            <div className='w-full flex-1 flex flex-col justify-center items-center'>
                <img src={ImgWhatsapp} className="  w-[80px] object-contain" />
                <h1 className='mt-4 text-[18px] text-primary1-white font-bold text-center'>Join us via Whatsapp phone number</h1>
                <p className='mt-3 text-[12px] text-[#999] font-medium'>We'll send a code to your WHATSAPP</p>
                <div className=" login-phone-input mt-10 w-full h-[42px] ">
                    <IntlTelInput
                        ref={_phoneInput}
                        onChangeCountry={(v) => { console.log('code', v) }}
                        // onChangeValidity={(v) => { console.log('validity', v) }}
                        // onChangeErrorCode={(v) => { console.log('error code', v) }}
                        initOptions={{
                            initialCountry: "my",
                            useFullscreenPopup: false,
                        }}
                        inputProps={{
                            placeholder: 'Phone number',
                            onInput: (e) => {
                                setPhone(e?.target?.value);
                            },
                        }}
                    />
                </div>
                {loading ?
                    <div className='mt-9 w-full flex justify-center items-center h-[42px]'>
                        <Spin />
                    </div>
                    :
                    <button className='mt-9 w-full flex justify-center items-center h-[42px] rounded-[4px] bg-primary2 text-white text-[14px] font-semibold' onClick={onSubmit}>
                        Next
                    </button>
                }
                <p className='mt-8 text-[12px] text-[#999] font-medium text-center'>Joining our app means you agree with our <Link className=' underline' to={'/terms'}>Terms of Use</Link> and <Link className=' underline' to={'/privacy'}>Privacy Policy</Link></p>
            </div>
            <div className='w-full  flex justify-around items-center'>
                <Link to={'/about-us'}>
                    <div className='flex flex-col justify-center items-center'>
                        <FaUsers size={32} className={'text-primary1-white'}/>
                        <p className='text-[13px] text-primary1-white mt-1 font-semibold'>About Us</p>
                    </div>
                </Link>
                <Link to={'/contacts'}>
                    <div className='flex flex-col justify-center items-center'>
                        <FaPhoneAlt size={26} className={'text-primary1-white'}/>
                        <p className='text-[13px] text-primary1-white mt-1 font-semibold'>Contact Us</p>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default Login;