export const APP_NAME = "HEROMAY";
export const API_CORE_URL = 'https://heromay.com/backend/api/v1/';
// export const API_CORE_URL = 'http://localhost:8000/api/v1/';
export const APP_KEY = 'heromay-sn-24011xC0R4-web!';
export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

export const JOB_STATUS = {
    NEW: 0,
    ACCEPTED: 1,
    COMPLETED: 2,
    CANCELLED: 3,
    INCOMPLETED: 4,
}

export const USER_TYPE = {
    customer: 0,
    hero: 1
}

export const GENDER_TYPE = {
    male: 0,
    female: 1
}

export const KUALALUMPUR_ADDRESS = {
    'country': 'Malaysia',
    'state': 'KUALA LUMPUR',
    'city': 'KUALA LUMPUR',
    'address_line1': 'Kuala lumpur',
    'address_line2': '',
    'postcode': '50470',
    'latitude': 3.140853,
    'longitude': 101.693207,
};

export const DEFAULT_LANG = 'en';

export const Langs = [
    {
        id: 'en',
        title: 'EN',
        name: 'English'
    },
    {
        id: 'cn',
        title: 'CN',
        name: 'Chinese'
    },
    {
        id: 'ml',
        title: 'ML',
        name: 'Malay'
    },
];


export const TaskExamples = [
    'Wash my dirty shoes please',
    'Play Game with me, I pay u USD10 if you win. You no need to pay me if you lose',
    'Play badminton with me for 3 hours',
    'Assemble IKEA furniture for me please',
    'Walk my dog for an hour',
    'Move furniture to a new apartment',
    'Paint a room in my house',
    'Fix a leaky water and air-con noisy sound',
    'Babysit my children for a few hours',
    'Clean my car inside and out',
    'Cook a meal for a dinner party',
    "Tutor me in a subject I'm struggling with",
    'Organize my closet and garage',
    'pump my car tyre which no air already',
    'Take umbrella cover me cross a street',
    'Cure my sick cat urgent, it sick so serious',
    'Help me finish my works urgent, too much works to do'
];

export const currencies = [
    {
        value: '$',
        label: 'USD',
    },
    {
        value: 'rmb',
        label: 'RMB',
    },
    {
        value: 'rm',
        label: 'RM',
    },
];

export const ClothColors = {
    Black: '#000',
    Blue: '#00f',
    Brown: '#803201',
    Cyan: '#00f5ff',
    Green: '#0acb08',
    Grey: '#797979',
    Orange: '#ff7f00',
    Pink: '#ff64a6',
    Purple: '#4d039a',
    Red: '#f00',
    White: '#fff',
    Yellow: '#ff0'
}

export const SocialMapScreenStyles = [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: '#f5f5f5'
        }
      ]
    },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161'
        }
      ]
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#f5f5f5'
        }
      ]
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#bdbdbd'
        }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee'
        }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575'
        }
      ]
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5'
        }
      ]
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e'
        }
      ]
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff'
        }
      ]
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575'
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#dadada'
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161'
        }
      ]
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e'
        }
      ]
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5'
        }
      ]
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee'
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#c9c9c9'
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e'
        }
      ]
    }
  ];