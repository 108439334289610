import React, { useEffect, useMemo, useRef, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { currencies, GOOGLE_MAP_API_KEY, SocialMapScreenStyles, TaskExamples } from '../../constants/config';
import LocationMarker from '../../components/Markers/LocationMarker';
import PersonMarker from '../../components/Markers/PersonMarker';
import { useSelector } from 'react-redux';
import { FloatButton } from 'antd';
import { FaCarSide, FaWalking, FaRoute } from "react-icons/fa";
import './index.css';

const HeroMap = () => {
    const { user } = useSelector(state => state.auth);
    const { activeJob } = useSelector(state => state.app);
    const _MAP = useRef(null);
    const _MAPS = useRef(null);
    const directionsService = useRef(null);
    const directionsRenderer = useRef(null);
    const mapView = useRef(null);
    const [isMapLoaded, setIsMapLoaded] = useState(false);
    const [mapCenter, setMapCenter] = useState({
        latitude: user.cur_latitude, longitude: user.cur_longitude
    });
    const [viewMode, setViewMode] = useState(null);
    const [isRouteBtnActive, setIsRouteBtnActive] = useState(false);

    useEffect(() => {
        if (isMapLoaded && user) {
            fetchDirections()
        }
    }, [isMapLoaded, user])

    const handleApiLoaded = (map, maps) => {
        _MAPS.current = maps;
        _MAP.current = map;

        map.setTilt(0);
        setIsMapLoaded(true);
        directionsService.current = new maps.DirectionsService();
        directionsRenderer.current = new maps.DirectionsRenderer({
            suppressMarkers: true,
            polylineOptions: {
                strokeColor: '#007bff',
                strokeOpacity: 1,
                strokeWeight: 9,
            },
        });

        directionsRenderer.current.setMap(map);
    };

    const fetchDirections = () => {
        if (directionsService.current == null) { return; }
        directionsService.current.route(
            {
                origin: new _MAPS.current.LatLng(parseFloat(user.cur_latitude), parseFloat(user.cur_longitude)),
                destination: new _MAPS.current.LatLng(parseFloat(activeJob?.latitude), parseFloat(activeJob?.longitude)),
                travelMode: _MAPS.current.TravelMode.DRIVING,
            },
            (result, status) => {
                if (status === _MAPS.current.DirectionsStatus.OK) {
                    directionsRenderer.current.setDirections(result);

                    setTimeout(() => {
                        _MAP.current.setTilt(45);
                        _MAP.current.setZoom(18);
                        result.routes[0].legs.forEach(leg => {
                            setMapCenter({ latitude: leg.start_location.lat(), longitude: leg.start_location.lng() })
                        });
                    }, 2000)

                    console.log('directions ', result)
                } else {
                    this.setState({ error: result });
                }
            }
        );
    }

    if (activeJob == null) {
        return null;
    }
    if (mapCenter.latitude == null || mapCenter.longitude == null) {
        return null;
    }

    console.log('isRouteBtnActive ', isRouteBtnActive)
    return (
        <div className='w-full flex-1 relative' ref={mapView}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY, libraries: ['places', 'geometry', 'drawing'] }}
                center={[parseFloat(mapCenter.latitude), parseFloat(mapCenter.longitude)]}
                defaultCenter={[parseFloat(mapCenter.latitude), parseFloat(mapCenter.longitude)]}
                defaultZoom={14}
                options={{
                    // tilt: tilt,
                    // heading: 90,
                    mapId: '7d8f9c62a3df4abc',
                    // mapTypeId: 'satellite',
                    // mapTypeId: 'roadmap',
                    zoomControl: false,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: true,
                    fullscreenControl: false,
                    // styles: SocialMapScreenStyles
                }}
                yesIWantToUseGoogleMapApiInternals={true}
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            >
                <LocationMarker lat={parseFloat(activeJob?.latitude)} lng={parseFloat(activeJob?.longitude)} />
                {
                    user != null &&
                    (
                        <PersonMarker
                            gender={user.gender}
                            lat={parseFloat(user.cur_latitude)} lng={parseFloat(user.cur_longitude)}
                        />
                    )
                }
            </GoogleMapReact>
            {
                activeJob?.customer_coming &&
                <div className='w-full text-center absolute top-9 text-[15px] text-primary2 font-semibold ' style={{ top: '30px' }}>
                    Customer is coming
                </div>
            }
            <FloatButton.Group
                trigger="click"
                style={{
                    insetInlineEnd: 24,
                    position: 'absolute',
                    right: 30,
                }}
                className={`heromap-float-btn ${viewMode != null ? 'active' : 'inactive'}`}
                open={isRouteBtnActive}
                icon={
                    viewMode == 'walk' ? <FaWalking className='text-white' /> :
                        viewMode == 'car' ? <FaCarSide className='text-white' /> : <FaRoute className='text-white' />
                }
                closeIcon={<FaRoute className='text-white' />}
                onOpenChange={setIsRouteBtnActive}
                onClick={() => {
                    console.log('clicked ')
                    setIsRouteBtnActive(pre => !pre)
                }}
            >
                <FloatButton className={`heromap-walk-btn ${viewMode == 'walk' ? 'active' : 'inactive'}`} icon={<FaWalking className='text-white' />}
                    onClick={() => {
                        setViewMode(pre => pre == 'walk' ? null : 'walk');
                        mapView.current?.click();
                    }}
                />
                <FloatButton className={`heromap-car-btn ${viewMode == 'car' ? 'active' : 'inactive'}`} icon={<FaCarSide className='text-white' />}
                    onClick={() => {
                        setViewMode(pre => pre == 'car' ? null : 'car');
                        mapView.current?.click();
                    }}
                />
            </FloatButton.Group>
        </div>
    )
}


function arePropsEqual(prevProps, nextProps) {
    return true;
}

export default React.memo(HeroMap, arePropsEqual);