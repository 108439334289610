import React, { useEffect, useMemo, useRef, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { currencies, GOOGLE_MAP_API_KEY, SocialMapScreenStyles, TaskExamples } from '../../constants/config';
import LocationMarker from '../../components/Markers/LocationMarker';
import PersonMarker from '../../components/Markers/PersonMarker';

const GoogleMap = ({ center, user }) => {
    const _MAP = useRef(null);
    const _MAPS = useRef(null);
    const directionsService = useRef(null);
    const directionsRenderer = useRef(null);
    const [isMapLoaded, setIsMapLoaded] = useState(false);

    useEffect(() => {
        if (isMapLoaded && user) {
            fetchDirections()
        }
    }, [isMapLoaded, user])

    const handleApiLoaded = (map, maps) => {
        _MAPS.current = maps;
        _MAP.current = map;
        setIsMapLoaded(true);
        // console.log(' reoute ', map, maps,)
        map.setOptions({ disableDefaultUI: false, zoomControl: true });
        // map.setOptions({ gestureHandling: 'cooperative' }); // Adjust to control user interaction

        directionsService.current = new maps.DirectionsService();
        directionsRenderer.current = new maps.DirectionsRenderer({
            suppressMarkers: true
        });

        directionsRenderer.current.setMap(map);
    };

    const fetchDirections = () => {
        if (directionsService.current == null) { return; }
        directionsService.current.route(
            {
                origin: new _MAPS.current.LatLng(parseFloat(center.latitude), parseFloat(center.longitude)),
                destination: new _MAPS.current.LatLng(parseFloat(user.cur_latitude), parseFloat(user.cur_longitude)),
                travelMode: _MAPS.current.TravelMode.DRIVING,
            },
            (result, status) => {
                if (status === _MAPS.current.DirectionsStatus.OK) {
                    directionsRenderer.current.setDirections(result);
                } else {
                    this.setState({ error: result });
                }
            }
        );
    }

    if (center.latitude == null || center.longitude == null) {
        return null;
    }
    return (
        <GoogleMapReact
            bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY, libraries: ['places', 'geometry', 'drawing'] }}
            center={[parseFloat(center.latitude), parseFloat(center.longitude)]}
            defaultCenter={[parseFloat(center.latitude), parseFloat(center.longitude)]}
            defaultZoom={14}
            options={{
                // mapTypeId: 'roadmap',
                // zoomControl: false,
                // mapTypeControl: false,
                // scaleControl: false,
                // streetViewControl: false,
                // rotateControl: false,
                // fullscreenControl: false,
                // styles: SocialMapScreenStyles
            }}
            yesIWantToUseGoogleMapApiInternals={true}
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        >
            <LocationMarker lat={parseFloat(center.latitude)} lng={parseFloat(center.longitude)} />
            {
                user != null &&
                (
                    <PersonMarker
                        gender={user.gender}
                        lat={parseFloat(user.cur_latitude)} lng={parseFloat(user.cur_longitude)}
                    />
                )
            }
        </GoogleMapReact>
    )
}

export default GoogleMap;