import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import { MdStar, MdOutlineWorkOutline, MdOutlineLocationOn, MdPermPhoneMsg } from "react-icons/md";
import { HiArrowLeft } from "react-icons/hi";
import { CiEdit } from "react-icons/ci";
import LinearProgress from '@mui/material/LinearProgress';
import { currencies, GOOGLE_MAP_API_KEY, SocialMapScreenStyles, TaskExamples } from '../../constants/config';
import { useDispatch, useSelector } from 'react-redux';
import LocationMarker from '../../components/Markers/LocationMarker';
import JobService from '../../services/JobService';
import { NotificationManager } from "react-notifications";
import PriceInputModal from '../../components/PriceInputModal';
import { getActiveJob, getHeroActiveBid } from '../../redux/actions/app';
import Avatar from '../../components/Avatar';
import { isEmpty } from '../../utils/common';
import CancelJobModal from '../../components/CancelJobModal';
import { Spin } from 'antd';
import PersonMarker from '../../components/Markers/PersonMarker';

const HeroTypeTab = ({ data, isSelected, onSelect }) => {
    return (
        <div className={`px-2 py-1 rounded-[6px] ${isSelected ? 'bg-[#E3F4FE]' : 'bg-white'}  flex justify-center items-center`}
            onClick={() => onSelect(data)}
        >
            <img src={data?.img} className="h-[45px] object-contain" />
            <span className='ml-2 text-[#000] text-[14px] font-semibold'>{data?.label}</span>
        </div>
    )
}

const WaitHeros = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { activeJob } = useSelector(state => state.app);
    const { user } = useSelector(state => state.auth)
    const _timer = useRef(null);
    const [heros, setHeros] = useState([]);
    const [loadingMinus, setLoadingMinus] = useState(false);
    const [loadingPlus, setLoadingPlus] = useState(false);
    const [curLoc, setCurLoc] = useState({});
    const [showCancelModal, setShowCancelModal] = useState(false);

    useEffect(() => {
        setCurLoc({
            latitude: user?.cur_latitude,
            longitude: user?.cur_longitude,
            country: user?.address?.country,
            city: user?.address?.city,
            address_line1: user?.address?.address_line1,
        })
    }, [user])

    useEffect(() => {
        if (_timer.current != null) {
            clearInterval(_timer.current);
        }
        _timer.current = setInterval(function () {
            refreshHeroViwers();
        }, 10000);
        refreshHeroViwers();
        return () => {
            if (_timer.current != null) {
                clearInterval(_timer.current);
            }
        }
    }, [])

    const refreshHeroViwers = () => {
        JobService.getSeenHeroList({
            job_id: activeJob?.id
        }).then((res) => {
            setHeros(res.jobSeens || [])
        }).catch(e => {
        });
    }

    const handleUpdatePrice = async (price, isMinus) => {
        if (price < 0) {
            return;
        }
        if (isMinus) {
            setLoadingMinus(true);
        }
        else {
            setLoadingPlus(true);
        }

        try {
            await JobService.updateCustomerPrice({
                job_id: activeJob?.id,
                price: price,
            });
            await dispatch(getActiveJob());
            if (isMinus) {
                setLoadingMinus(false);
            }
            else {
                setLoadingPlus(false);
            }
        } catch (error) {
            console.log(error)
            if (isMinus) {
                setLoadingMinus(false);
            }
            else {
                setLoadingPlus(false);
            }
            NotificationManager.error(
                error?.message ||
                "Oops! Something went wrong. Please try again or contact us directly at contact@heromay.com.",
                "Error",
                3000
            );
        }
    }

    const priceStep = useMemo(() => {
        return parseInt(parseInt(activeJob?.price) / 3);
    }, [activeJob])

    const handleCancelJob = (reason) => {
        JobService.cancelJob({
            job_id: activeJob?.id,
            reason: reason
        }).then((res) => {
            console.log('cancel job ', res)
            navigate('/customer');
        }).catch(e => {
            console.log(e)
            NotificationManager.error(
                e?.message ||
                "Oops! Something went wrong. Please try again or contact us directly at contact@heromay.com.",
                "Error",
                3000
            );
        });
    }

    return (
        <div className='w-full h-full bg-white flex flex-col justify-center items-center'>
            <div className='w-full flex-1 relative'>
                {
                    curLoc.latitude != null && curLoc.longitude != null &&
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY, libraries: ['places', 'geometry'] }}
                        center={[parseFloat(curLoc.latitude), parseFloat(curLoc.longitude)]}
                        defaultCenter={[parseFloat(curLoc.latitude), parseFloat(curLoc.longitude)]}
                        defaultZoom={14}
                        options={{
                            zoomControl: false,
                            mapTypeControl: false,
                            scaleControl: false,
                            streetViewControl: false,
                            rotateControl: false,
                            fullscreenControl: false,
                            styles: SocialMapScreenStyles
                        }}
                    >
                        <LocationMarker lat={parseFloat(curLoc.latitude)} lng={parseFloat(curLoc.longitude)} />
                        {
                            heros.filter(h => h.hero != null).map(item =>
                                <PersonMarker
                                    key={item.id}
                                    gender={item.hero.gender}
                                    lat={parseFloat(item.hero.cur_latitude)} lng={parseFloat(item.hero.cur_longitude)}
                                />
                            )
                        }
                    </GoogleMapReact>
                }
            </div>
            <div className='w-full rounded-tl-[10px] rounded-tr-[10px] bg-primary1 drop-shadow'>
                {
                    heros.length > 0 &&
                    <div className='w-full rounded-tl-[10px] rounded-tr-[10px] px-5 py-3 bg-black2'>

                        <div className='w-full flex justify-center items-center gap-4'>
                            <p className='text-[13px] text-[#eee] flex-1'>{heros.length} Hero are viewing your offer</p>
                            <div className='flex justify-center items-center'>
                                {
                                    heros.slice(0, 5).map((hero) =>
                                        <Avatar image={user?.photo} className={`!w-[25px] !h-[25px] min-w-[25px] ml-[-8px]`} />
                                    )
                                }
                                {
                                    (heros.length > 5) &&
                                    <div className='w-[25px] h-[25px] ml-[-8px] border border-[#111] bg-[#444] rounded-full flex justify-center items-center text-[10px] text-[#bbb]'>
                                        +{heros.length - 5}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }
                <div className='w-full  px-5'>
                    <div className="w-full flex flex-col py-3  justify-center items-center  "  >
                        <h2 className='text-[24px] text-white font-bold'>Waiting Hero to accept</h2>
                        <LinearProgress style={{ width: '100%', marginTop: 15, backgroundColor: '#aaa' }} />
                    </div>
                    <div className='w-full flex flex-col justify-center items-center '>
                        <p className='text-[15px] text-white font-semibold'>Your offer</p>
                        <div className='w-full flex justify-center items-center'>
                            {
                                loadingMinus ?
                                    <div className='flex justify-center items-center px-8 h-[40px]'>
                                        <Spin />
                                    </div>
                                    :
                                    <button className='bg-primary2 px-8 h-[40px] rounded-[10px] text-[15px] text-white'
                                        onClick={() => handleUpdatePrice((parseInt(activeJob?.price) - priceStep), true)}
                                    >-{priceStep}</button>
                            }
                            <h1 className='flex-1 text-center text-[24px] text-white font-bold'>RM {parseInt(activeJob?.price)}</h1>
                            {
                                loadingPlus ?
                                    <div className='flex justify-center items-center px-8 h-[40px]'>
                                        <Spin />
                                    </div>
                                    :
                                    <button className='bg-primary2 px-8 h-[40px] rounded-[10px] text-[15px] text-white'
                                        onClick={() => handleUpdatePrice((parseInt(activeJob?.price) + priceStep), false)}
                                    >+{priceStep}</button>
                            }
                        </div>
                    </div>
                    <p className='mt-6 text-[15px] text-[#aaa] font-semibold'>Your offer job:</p>
                    <div className="w-full mt-2 flex justify-start items-center">
                        <MdOutlineWorkOutline color="#fff" size={16} />
                        <p className="ml-2 w-full text-[16px] leading-[24px] text-white font-bold">{activeJob?.title}</p>
                    </div>
                    <div className="w-full mt-2 flex justify-start items-center">
                        <MdOutlineLocationOn color="#fff" size={16} />
                        <p className="ml-2 w-full text-[16px] leading-[21px] text-white ">{activeJob?.address_line1} {activeJob?.city} {activeJob?.country}</p>
                    </div>
                    <div className='w-full p-4 flex flex-col justify-center items-center'>
                        <button className='w-full h-[42px] rounded-[8px] bg-black2 text-[#f00] text-[16px] font-semibold'
                            onClick={() => setShowCancelModal(true)}
                        >
                            Cancel request
                        </button>
                    </div>
                </div>
            </div>
            {
                showCancelModal &&
                <CancelJobModal
                    showModal={showCancelModal}
                    onClose={(data) => {
                        setShowCancelModal(false);
                        if (!isEmpty(data)) {
                            handleCancelJob(data);
                        }
                    }}
                />
            }
        </div>
    )
}

export default WaitHeros;